import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { ResponsiveService } from '@cf/core';
import { AuthService, connectCalendar } from '@cf/temp/services';
import type { Observable } from 'rxjs';

import { AsyncPipe, NgIf } from '@angular/common';
import { OrganizationPickerComponent } from '@cf/features/organizations/admin';
import { LinkComponent } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { TextComponent } from '@cf/shared/ui/typography';
import {
  AccountQuery,
  AccountService,
  OrganizationsRolesEnum,
  OrganizationsStore,
} from '@cf/temp/services';
import { Actions } from '@ngneat/effects-ng';
import { TranslateModule } from '@ngx-translate/core';
import { CreateButtonComponent } from '../create-button/create-button.component';
@Component({
  selector: 'cf-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    NgIf,
    AsyncPipe,
    CreateButtonComponent,
    TextComponent,
    TranslateModule,
    LinkComponent,
    CfIconDirective,
    OrganizationPickerComponent,
  ],
})
export class HeaderComponent {
  public readonly authService = inject(AuthService);
  public readonly accountService = inject(AccountService);
  private readonly accountQuery = inject(AccountQuery);
  private readonly responsiveService = inject(ResponsiveService);

  toggleSidenav = output();

  isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  showCalendarAlert$ = this.accountQuery.showCalendarAlert$;

  showCalendarAlert = true;

  #actions = inject(Actions);
  #organizationStore = inject(OrganizationsStore);
  hideCreateButton = computed(
    () => this.#organizationStore.selectedEntity()?.role === OrganizationsRolesEnum.MEMBER,
  );

  openWindow() {
    this.#actions.dispatch(connectCalendar({ newUser: false }));
  }
}
