import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { RouterLink } from '@angular/router';
import { injectEnvironment } from '@cf/core';
import { OrgRouterLinkDirective } from '@cf/features/organizations/admin';
import { MainLogoComponent } from '@cf/shared/ui/logos';

@Component({
  selector: 'cf-footer',
  imports: [CommonModule, MainLogoComponent, RouterLink, OrgRouterLinkDirective],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  public year = new Date().getFullYear();

  protected environment = injectEnvironment();
}
