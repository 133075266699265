<div class="overlay" (click)="toggleSidenav()" *ngIf="isTouch$ | async"></div>

<div class="wrapper tw:z-100">
  <section>
    <ng-container *ngIf="(isTouch$ | async) === false">
      @if (pinned()) {
        <svg cfIcon="double_arrow_back" size="sm" class="pin" (click)="immediatelyClose()"></svg>
      } @else {
        <svg cfIcon="pin" class="pin" (click)="pinNavigation()"></svg>
      }
      <!-- <cf-icon *ngIf="!pinned" icon="pin" class="pin" (click)="pinNavigation()"></cf-icon> -->
      <!-- <cf-icon size="sm" *ngIf="pinned" icon="double_arrow_back" class="pin" (click)="immediatelyClose()"></cf-icon> -->
    </ng-container>

    <svg *ngIf="isTouch$ | async" cfIcon="close" class="pin tw:text-gray-70" size="sm" (click)="toggleSidenav()"></svg>

    <div
      cf-main-logo
      size="sm"
      logoHeight="32"
      class="mt-xl-3 logo-sm"
      routerLink="/dashboard"
      cfOrgRouterLink
      [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
      (click)="closeSidenav()"
    ></div>
    <div
      cf-main-logo
      size="lg"
      logoHeight="40"
      class="mt-xl-3 logo-lg"
      (click)="closeSidenav()"
      routerLink="/dashboard"
      cfOrgRouterLink
      [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
    ></div>
    <cf-organization-picker class="tw:mt-4" [compact]="!pinned() && !hovered() && !isTouch()" />

    <div class="tw:min-h-0.5 tw:w-full tw:bg-raven-10 tw:my-4 tw:flex-0"></div>

    @if (!hideCreateButton()) {
      <cf-create-button
        class="tw:w-full"
        *ngIf="isAuthorized"
        (emitNavigate)="closeSidenav()"
        [compact]="!pinned() && !hovered() && !isTouch()"
      ></cf-create-button>
    }

    <div class="menu-items mt-4" *ngIf="isAuthorized()">
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/dashboard"
        cfOrgRouterLink
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/dashboard')"
        (click)="closeSidenav()"
      >
        <!-- <cf-icon icon="house" color="grey-80"></cf-icon> -->
        <svg cfIcon="house"></svg>
        <span class="item">
          {{ "dashboard" | translate }}
        </span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/events/list"
        cfOrgRouterLink
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/events/list')"
        (click)="closeSidenav()"
      >
        <!-- <cf-icon icon="calendar" color="grey-80"></cf-icon> -->
        <svg cfIcon="calendar_new"></svg>
        <span class="item">
          {{ "events.title" | translate }}
        </span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/groups"
        cfOrgRouterLink
        routerLinkActive="active"
        [queryParamsHandling]="getQueryParamsHandling('/groups')"
        (click)="closeSidenav()"
      >
        <!-- <cf-icon icon="documents" color="grey-80"></cf-icon> -->
        <svg cfIcon="documents"></svg>
        <span class="item">
          {{ "groups" | translate }}
        </span>
      </a>
    </div>

    <div class="menu-items mt-xl-auto" *ngIf="isAuthorized()">
      <div class="line"></div>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/integrations/list"
        routerLinkActive="active"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ type: '' }"
        (click)="closeSidenav()"
      >
        <!-- <cf-icon icon="grid" color="grey-80"></cf-icon> -->
        <svg cfIcon="grid"></svg>
        <span class="item">{{ "integration.title" | translate }}</span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/integrations/list"
        routerLinkActive="active"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ type: 'calendars' }"
        (click)="closeSidenav()"
      >
        <!-- <cf-icon icon="calendar_blank" color="grey-80"></cf-icon> -->
        <svg cfIcon="calendars"></svg>
        <span class="item">{{ "calendars.title" | translate }}</span>
      </a>
      <a
        cf-link
        size="sm"
        color="grey-80"
        routerLink="/integrations/list"
        routerLinkActive="active"
        [queryParamsHandling]="'merge'"
        [queryParams]="{ type: 'meeting_tools' }"
        (click)="closeSidenav()"
      >
        <!-- <cf-icon icon="video" color="grey-80"></cf-icon> -->
        <svg cfIcon="video"></svg>
        <span class="item">{{ "header.meeting_tools" | translate }}</span>
      </a>
    </div>

    @if (isTouch$ | async) {
      <div class="tw:mt-auto tw:w-full">
        <div class="tw:min-h-0.5 tw:w-full tw:bg-raven-10 tw:my-4 tw:flex-0"></div>
        @if (accountService.account$ | async; as account) {
          <div
            class="tw:flex tw:items-center tw:gap-2 tw:h-10 tw:text-sm tw:font-medium tw:leading-snug tw:w-full tw:min-w-0"
          >
            <cf-avatar [src]="account.profile_picture"></cf-avatar>
            <span class="tw:truncate tw:min-w-0">{{ account.name }} </span>
          </div>
          <a
            cfButton
            variant="ghost"
            class="tw:w-full tw:justify-start tw:px-0 tw:text-gray-80"
            routerLink="/settings/profile"
            routerLinkActive="active"
            (click)="closeSidenav()"
          >
            <svg cfIcon="user"></svg>
            {{ "header.my_profile" | translate }}
          </a>
          <button
            cfButton
            variant="ghost"
            class="tw:w-full tw:justify-start tw:px-0 tw:text-red-50"
            routerLink="/settings/profile"
            routerLinkActive="active"
            (click)="logout(); closeSidenav()"
          >
            <svg cfIcon="log_out"></svg>
            {{ "header.logout" | translate }}
          </button>
        }
      </div>
    }
  </section>
</div>
