import { Routes, UrlMatcher, UrlSegment } from '@angular/router';
import { authorizedGuard, superAdminGuard } from '@cf/features/auth/shell';
import { legacyURLredirectionGuard } from '@cf/features/booking';

const bookingPageMatcher: UrlMatcher = (url: UrlSegment[]) => {
  // Return null if URL is empty or first segment is in excluded paths
  if (
    url.length === 0 ||
    ['home', 'default', 'group-dashboard', 'error', 'registrations'].includes(url[0].path)
  ) {
    return null;
  }

  return {
    consumed: url,
    posParams: {
      username: url[0],
    },
  };
};

export const appRoutes: Routes = [
  {
    path: 'stats',
    canActivate: [superAdminGuard],
    loadChildren: () =>
      import('@cf/features/admin-statistic').then((m) => m.featureStatisticRoutes),
  },

  {
    path: 'sandbox',
    loadChildren: () =>
      import('@cf/features/admin-statistic').then((m) => m.featureStatisticRoutes),
  },

  {
    path: 'auth',
    loadChildren: () => import('./libs/auth').then((m) => m.routes),
    canActivate: [authorizedGuard],
  },

  {
    path: '',
    loadChildren: () => import('./modules/routing/app-container/routes').then((m) => m.routes),
    // canActivate: [authGuard],
  },

  {
    path: 'booking',
    loadChildren: () => import('./modules/routing/booking/routing').then((m) => m.bookingRoutes),
    canActivate: [legacyURLredirectionGuard],
  },

  {
    path: 'error',
    loadChildren: () => import('./modules/routing/error/routes').then((m) => m.routes),
  },

  {
    matcher: bookingPageMatcher,
    loadChildren: () => import('./modules/routing/booking/routing').then((m) => m.bookingRoutes),
    canActivate: [legacyURLredirectionGuard],
  },

  {
    path: 'b/:username',
    loadChildren: () => import('./modules/routing/booking/routing').then((m) => m.bookingRoutes),
    canActivate: [legacyURLredirectionGuard],
  },

  {
    path: '**',
    redirectTo: 'error/404',
  },
];
