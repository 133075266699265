import { popperVariation } from '@ngneat/helipopper/config';

import { tooltipVariation } from '@ngneat/helipopper/config';

import { provideTippyConfig, provideTippyLoader } from '@ngneat/helipopper/config';
import { followCursor } from 'tippy.js';

export function initTippy() {
  return [
    provideTippyLoader(() => import('tippy.js')),
    provideTippyConfig({
      defaultVariation: 'tooltip',
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        followCursor: {
          ...popperVariation,
          plugins: [followCursor],

          trigger: 'mouseenter',
          placement: 'left',
          followCursor: 'vertical',
          arrow: `<svg width="20" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.248 10.946 1.353 0h17.296l-8.401 10.946Z" fill="#fff"/><path fill-rule="evenodd" clip-rule="evenodd" d="m10.26 12 9.737-12h-1.348l-8.413 10.598L1.353 0H-.003L10.26 12Z" fill="#3158E3"/></svg>`,
        },
      },
    }),
  ];
}
