import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { OrgRouterLinkDirective } from '@cf/features/organizations/admin';
import { PopoverComponent } from '@cf/shared/dropdown';
import { ButtonDirective } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cf-create-button',
  template: `
    <cf-popover
      [popoverToggle]="popToggle"
      [popoverTemplate]="popContent"
      placement="auto"
      popoverClass="z-1003 p-0"
      [class.full-width]="fullWidth()"
      [fullWidth]="true"
      triggers="click"
      #popoverComponent
      [class.tw:w-10]="compact()"
      [class.tw:w-full]="!compact()"
    >
      <ng-template #popToggle>
        <button
          cfButton
          [size]="compact() ? 'icon' : 'default'"
          variant="yellow"
          [class.tw:w-full]="true"
        >
          @if (!compact()) {
            <span class="tw:truncate">
              {{ 'create' | translate }}
            </span>
          }
          <svg cfIcon="plus" class="tw:!mr-0 tw:!ml-0"></svg>
        </button>
      </ng-template>

      <ng-template #popContent>
        <div class="tw:flex tw:flex-col">
          <div
            routerLink="/meeting/create"
            cfOrgRouterLink
            (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
            class="tw:flex tw:p-8 tw:pb-0 tw:cursor-pointer tw:hover:bg-gray-10 tw:rounded-t-lg"
          >
            <img
              class="tw:w-10 tw:h-10 tw:mr-4"
              alt="One to One"
              src="assets/illustration/one_to_one_sm.svg"
            />
            <div class="tw:border-b tw:border-gray-200 tw:pb-4 tw:flex tw:flex-col">
              <span class="tw:text-[18px] tw:font-medium tw:mb-1">Booking Page</span>
              <span class="tw:text-sm tw:text-gray-70">
                Create a 1-to-1 or Group-to-1 Booking Page
              </span>
            </div>
          </div>

          <div
            routerLink="/events/flexible"
            cfOrgRouterLink
            (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
            class="tw:flex tw:p-8 tw:pt-6 tw:pb-0 tw:cursor-pointer tw:hover:bg-gray-10"
          >
            <img
              class="tw:w-10 tw:h-10 tw:mr-4"
              alt="Group events"
              src="assets/illustration/group_events.svg"
            />
            <div class="tw:border-b tw:border-gray-200 tw:pb-4 tw:flex tw:flex-col">
              <span class="tw:text-[18px] tw:font-medium tw:mb-1">Calendar Event</span>
              <span class="tw:text-sm tw:text-gray-70">
                Schedule events with one or many participants
              </span>
            </div>
          </div>

          <div
            routerLink="/groups/create"
            cfOrgRouterLink
            (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
            class="tw:flex tw:p-8 tw:cursor-pointer tw:hover:bg-gray-10 tw:rounded-b-lg"
          >
            <img
              class="tw:w-10 tw:h-10 tw:mr-4"
              alt="Group events"
              src="assets/illustration/group_to_one_md.svg"
            />
            <div class="tw:flex tw:flex-col">
              <span class="tw:text-[18px] tw:font-medium tw:mb-1">New Group</span>
              <span class="tw:text-sm tw:text-gray-70">
                Create groups and then schedule with ease
              </span>
            </div>
          </div>
        </div>
      </ng-template>
    </cf-popover>
  `,
  styleUrls: ['./create-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PopoverComponent,
    RouterLink,
    TranslateModule,
    CfIconDirective,
    ButtonDirective,
    OrgRouterLinkDirective,
  ],
})
export class CreateButtonComponent {
  fullWidth = input<boolean>(false);
  compact = input<boolean>(false);
  emitNavigate = output();
}
