import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { makeEnvironmentProviders } from '@angular/core';

import { BackendConfig } from './config/backend.config';

export function provideBackend(config: BackendConfig) {
  return makeEnvironmentProviders([
    provideHttpClient(withInterceptorsFromDi()),
    { provide: BackendConfig, useValue: config },
  ]);
}
