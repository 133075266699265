import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.calfrenzy.com/',
  appUrl: 'https://schedule.calfrenzy.com',
  publicUrl: 'https://calfrenzy.com/',
  bookingUrl: 'https://calfrenzy.co/',
  stripeId:
    'pk_live_51Pv0E5Kj2WT4T8Fn0O8oK4BdN1ZhfhI4mVTcESjQP2wLNy5W2B2U5ZD3rj6hnfSlfXaSl9bfReWFSbrDREBWHGuk00cRSmUlQc',
  domain: '.calfrenzy.com',
  envName: 'prod',
  sentryEnv: 'production',
  googleClientId: '369573383535-jtjq1arqc3220nbnn0j05ke4glrpr2h0.apps.googleusercontent.com',
  googleMapId: 'AIzaSyA-JbP7RYVDwqF3aAxVavSpcAHq67FfUVU',
  zoomClientId: 'L0KrA52XRYix0ytG7b7hSQ',
  buildVersion: '199cf739fb089f8b998f5aa143a55a87f93a67a1',
  facebookAppId: '981687840686487',
};
